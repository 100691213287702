import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { FormAssessmentResponse } from '../../models/forms/form-assessment-response.model';
import { FormSubmitRequest } from '../../models/forms/form-submit-request';
import {  Submit } from '../../models/forms/submit';
import subjectlessFormService from '../../services/subjectless-form.service';


export const submitForm = createAsyncThunk(
  'formAssessments/submit',
  async (
    {
      submitFormRequest,
    }: {
      submitFormRequest: FormSubmitRequest;
    },
    thunkApi,
  ) => {
    try {
      const response = await subjectlessFormService.submit(submitFormRequest);
      return response.data;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  },
);

export interface FormAssessmentState {
  loading: boolean;
  hasForm: boolean;
  error: string | null;
  entities: FormAssessmentResponse[] | FormAssessmentResponse;
  reference: string;
  submitForms: Array<Submit>;
}

const initialState = {
  loading: false,
  hasForm: false,
  error: null,
  entities: [],
  reference: '',
  submitForms: [],
} as FormAssessmentState;

const formAssessmentSlice = createSlice({
  name: 'formAssessments',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      
      .addCase(submitForm.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitForm.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.reference = action.payload;
      })
      .addCase(submitForm.rejected, (state, action: PayloadAction<any>) => {
        state.reference = '';
        state.error = action.payload;
      })
      .addDefaultCase((state, action) => {
        state.hasForm = false;
        state.entities = [];
      });
  },
});

const { reducer } = formAssessmentSlice;
export default reducer;
